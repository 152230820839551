.portpg-img {
    width: -webkit-fill-available;
    cursor: pointer;
}
.portpg-img-model.ant-modal .ant-modal-content {
    padding: 0%;
    background: none;
}
/**************************************************************************** ANTD OVER WRITTING*/
.ant-modal-root .ant-modal-mask {
    background-color: rgb(0 0 0 / 88%);
}