.web-footer {
    padding: 2% 2% 2px 2%;
    background: #444;
}
.web-footer h1 {
    color: #b1b1b1;;
    font-family: 'Jost', sans-serif !important;
    padding: 2% 0%;
    font-size: 2em;
}
.web-footer-list,
.web-footer-list a {
    list-style: none;
    line-height: 2;
    color: #e1e1e1;
    font-weight: 600;
    padding: 2% 0;
    font-family: 'Jost', sans-serif !important;
}
.web-footer-list a:hover {
    color: #fff;
}
.web-footer-main {
    border-bottom: 1px solid #585858;
    padding-bottom: 2%;
}
.web-footer-cpywrt {
    padding-top: 10px;
    color: #cccc;
}
.web-footer-anchor {
    padding: 10px;
    bottom: 5%;
    position: fixed;
    /*border-radius: 33px;
    background: #7894f7;*/
}
.web-footer-anchor-col {
    justify-content: flex-end;
    display: flex;
}
.web-footer-anchor a {
    color: #fff;
    font-size: 20pt;
}
.web-footer-contact-ico {
    font-size: 20pt;
    padding: 1% 2% 1% 0;
    color: #ffd782;
    cursor: pointer;
}
.web-footer-logo {
    width: 11%;
    padding: 1% 2% 1% 0%;
}
.web-footer-logoname {
    color: #e1b967 !important;
}
.web-footer-cpywrt-txt {
    font-size: 10pt;
}
.web-footer-sticky {
    width: 64px;
}

/************************************************************************************** RESPONSIVE */
@media screen and (min-width: 320px) and (max-width: 400px) {
    .web-footer-sticky {
        width: 48px;
    }
    .web-footer h1 {
        color: #b1b1b1;;
        font-family: 'Jost', sans-serif !important;
        padding: 2% 0%;
        font-size: 1.7em;
    }
    .web-footer-cols {
        padding-left: 8px;
    }
    .web-footer-logo {
        width: 13%;
    }
}
@media screen and (min-width: 401px) and (max-width: 576px) {
    .web-footer-logo {
        width: 13%;
    }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .web-footer-logo {
        width: 10%;
    }
}
@media screen and (min-width: 769px) and (max-width: 992px) {
    .web-footer-logo {
        width: 10%;
    }
}
@media screen and (min-width: 993px) and (max-width: 1200px) {
    .web-footer-logo {
        width: 13%;
    }
}
@media screen and (min-width: 1201px) and (max-width: 1600px) {
    .web-footer-logo {
        width: 15%;
    }
}