.landingpg {
    background-image: url('../../assets/images/landing.png');
    height: 100vh;
    overflow: hidden;
}
.landingpg-rightsideimg {
    width: 96vh;
}
.landingpg-menu {
    width: fit-content;
}
.landingpg-menu-btn {
    border: none;
    border-top-left-radius: 42%;
    border-bottom-left-radius: 42%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: #120879;
    color: #eaedf7;
    font-weight: 700;
    min-width: 106px;
    margin-top: 2%;
    display: flex;
    padding: 26%;
    align-items: center;
}
.landingpg-menu-btn:hover {
    background-color: #eaedf7;
    color: #120879 !important;
}
.landingpg-logo {
    width: 10%;
    margin: 2%;
}
.landingpg-rightside-col {
    display: block;
    overflow: hidden;
}
.landingpg-box {
    padding: 5%;
}
.landingpg-header {
    font-family: 'Jost', sans-serif !important;
    font-size: 36pt;
    color: #120879;
}
.landingpg-content {
    font-size: 16pt;
    font-family: 'Jost', sans-serif !important;
    padding: 3% 0px;
    font-weight: 500;
    color: #07004e;
    width: 75%;
}
.landingpg-start-btn {
    height: 45px;
    width: 25%;
    font-size: 12pt;
    font-weight: 700;
    color: #120879;
    background:  #eaedf7;
    border: none;
}
.landingpg-start-btn:hover {
    color: #120879 !important;
    background: #fff;
    border: none;
}

/********************************************************************* RESPONSIVE */
@media screen and (min-width: 320px) and (max-width: 400px) {
    .landingpg-menu-btn {
        min-width: 90px;
        padding: 23%;
    }
    .landingpg-logo {
        width: 33%;
        margin: 3%;
    }
    .landingpg-header {
        font-size: 20pt;
    }
    .landingpg-content {
        font-size: 12pt;
    }
    .landingpg-start-btn {
        width: 30%;
        font-size: 11pt;
    }
    .landingpg-cont-col {
        padding-top: 5%;
    }
}
@media screen and (min-width: 401px) and (max-width: 768px) {
    .landingpg-header {
        font-size: 26pt;
    }
    .landingpg-content {
        font-size: 12pt;
    }
    .landingpg-menu-btn {
        min-width: 90px;
        padding: 25%;
    }
    .landingpg-logo {
        width: 25%;
    }
}
@media screen and (min-width: 769px) and (max-width: 992px) {
    .landingpg-logo {
        width: 16%;
    }
    .landingpg-header {
        font-size: 30pt;
    }
    .landingpg-content {
        font-size: 14pt;
    }
}