.homepg-banner-img {
    width: 100vw;
    overflow: hidden;
}
/************************************************************************************* ABOUT*/
.homepg-abt-section {
    background-image: url(../../assets/images/about.png);
}
.homepg-abt-text {
    padding: 1% 20%;
    font-size: 12pt;
    font-family: 'Jost', sans-serif !important;
    line-height: 1.8;
}
/************************************************************************************* FEATURES*/
.homepg-features {
    padding: 1% 1%;
}
.homepg-feature-text {
    padding: 5% 0%;
    text-align: center;
    border-top: 3px dashed #bdc8f7;
}
.homepg-features .header-h2 {
    padding-bottom: 7%;
}
/************************************************************************************* BEFORE-AFTER*/
.homepg-ba-section {
    margin: auto 14%;
}
.homepg-ba-text {
    padding: 1% 0%;
}
.homepg-ba-section .before-after-slider__delimiter-icon{
    background-image: url(../../assets/images/logo-black.png);
    background-size: 100%;
}
.homepg-ba-section .before-after-slider__delimiter-icon {
    width: 35px;
    height: 35px;
}
.homepg-ba-section .slick-list {
    border-radius: 24px;
}
.homepg-ba-icons {
    padding: 1%;
}
.homepg-nxt-pre-btn {
    border: none;
    font-size: 20pt;
    color: #5f5f61 !important;
    background: none !important;
    box-shadow: none !important;
}
.homepg-nxt-pre-btn:hover {
    color: #7894f7 !important;
    background: none !important;
}
/************************************************************************************* SERVICES*/
.homepg-service-col {
    padding: 1% 0%;
}
.homepg-service-box {
    padding: 6% 2%;
    background: #ffffff;
    margin: auto 2%;
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}
.homepg-service-text {
    padding: 2% 1%;
    font-family: 'Jost', sans-serif !important;
    color: #505050;
    font-weight: 600;
}
/************************************************************************************* CLIENT*/
.homepg-client-section .header-h3 {
    text-align: left;
    padding-left: 0;
    padding-bottom: 2%;
    font-size: 1.17em;
}
.homepg-client-carousel {
    padding: 2% 10%;
    text-align: left;
}
.homepg-client-text {
    font-weight: 500;
}
/************************************************************************************* HIW*/
.homepg-hiw-box {
    background: #ffffff;
    display: flex;
    margin: auto 2%;
}
.homepg-hiw-h2 {
    display: inline-flex;
    align-items: center;
    padding: 3% 5%;
    border-left: 2px solid #4167ef26;
    color: #7e7e7e;
    font-size: 1.5em;
}
.homepg-hiw-ico {
    padding: 3% 6%;
}
.homepg-hiw-img {
    width: 64px;
}
.homepg-hiw-row {
    padding: 3% 0% 2% 0%;
}

/************************************************************************************** RESPONSIVE */
@media screen and (min-width: 320px) and (max-width: 400px) {
    .homepg-abt-text {
        padding: 1% 14%;
        font-size: 11pt;
    }
    .homepg-ba-section {
        margin: auto 8%;
    }
    .homepg-hiw-h2 {
        font-size: 1.2em;
    }
    .homepg-hiw-img {
        width: 48px;
    }
    .homepg-client-section .header-h3 {
        font-size: 1.1em;
    }
}
@media screen and (min-width: 401px) and (max-width: 768px) {
    .homepg-abt-text {
        padding: 1% 10%;
    }
    .homepg-ba-section {
        margin: auto 8%;
    }
    .homepg-hiw-img {
        width: 44px;
    }
    .homepg-hiw-h2 {
        font-size: 1.3em;
    }
}
@media screen and (min-width: 769px) and (max-width: 992px) {
    .homepg-ba-section {
        margin: auto 6%;
    }
}
@media screen and (min-width: 993px) and (max-width: 1200px) {
    .homepg-ba-section {
        margin: auto 8%;
    }
}