.contpg-list {
    list-style: none;
    line-height: 3;
    padding-left: 5%;
    color: rgb(100 100 100 / 88%);
    font-weight: 700;
    font-family: 'Jost', sans-serif !important;
}
.contpg-social-ico {
    font-size: 20pt;
    padding: 1% 2% 1% 0;
    color: #ff7885;
    cursor: pointer;
}