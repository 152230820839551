.careerpg-box {
    background-image: url(../../assets/images/career.png);
    background-size: 100%;
    background-repeat: no-repeat;
    /*height: 100vh;*/
    overflow: hidden;
}
.careerpg-job-box {
    background-color: #f38796;
    padding: 8% 6%;
    text-align: center;
    border-radius: 20%;
    box-shadow: rgb(0 0 0 / 40%) 0px 8px 24px;
}
.careerpg-job-img {
    width: 100px;
}
.careerpg-job-p {
    font-family: 'Jost', sans-serif !important;
    color: #fff;
    font-weight: 800;
    font-size: 14pt;
    padding-top: 4%;
}
.careerpg-job-line {
    margin: 14px 0;
    border-block-start: 1px solid #fda8b4;
}
.careerpg-box .header-h2,
.careerpg-form .header-h2 {
    padding: 1% 1% 2% 1%;
}
.careerpg-row {
    margin-bottom: 2%;
}
.careerpg-submit {
    margin-right: 1%;
}
/************************************************************************************** RESPONSIVE */
@media screen and (min-width: 320px) and (max-width: 400px) {
    .careerpg-box .header-h2,
    .careerpg-form .header-h2 {
        padding: 6% 3% 6% 3%;
    }
}
@media screen and (min-width: 401px) and (max-width: 576px) {
    .careerpg-box .header-h2,
    .careerpg-form .header-h2 {
        padding: 4% 3% 5% 3%;
    }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
    .careerpg-box .header-h2,
    .careerpg-form .header-h2 {
        padding: 2% 1% 4% 1%;
    }
}