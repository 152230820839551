.web-primary-btn {
    background: #9eb4ff;
    font-weight: 700;
    font-family: 'Jost', sans-serif !important;
    border: none;
    border-radius: 25px;
    color: #000000;
}
.web-primary-btn:hover {
    background: #e3e8fd;
    font-weight: 700;
    border: none;
    color: #343434 !important;
}
.web-secondary-btn {
    background: #e3e8fd;
    font-weight: 700;
    font-family: 'Jost', sans-serif !important;
    border: none;
    border-radius: 25px;
    color: #343434;
}
.web-secondary-btn:hover {
    background: #9eb4ff;
    font-weight: 700;
    border: none;
    color: #000000 !important;
}
.web-btn-white {
    background: #ffffff;
    font-weight: 700;
    font-family: 'Jost', sans-serif !important;
    border: none;
    border-radius: 25px;
    color: #6a6a6a;
}
.web-btn-white:hover {
    background: #e3e8fd;
    font-weight: 700;
    border: none;
    color: #000000 !important;
}
.web-btn-grey{
    background: #d5d5d5;
    font-weight: 700;
    font-family: 'Jost', sans-serif !important;
    border: none;
    border-radius: 25px;
    color: #000000;
}
.web-btn-grey:hover {
    background: #a9a9a9;
    font-weight: 700;
    border: none;
    color: #000000 !important;
}