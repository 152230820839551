@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Righteous&display=swap');

:root {
  --primaryFontFamily: 'Jost', sans-serif;
  --secondaryFontFamily: 'Kanit', sans-serif;
  --normalFontFamily: 'Righteous', cursive;
}

@import "assets/styles/button.css";
@import "assets/styles/form.css";
/******************************************************************************** PATTERN */
.web-sections-light {
  padding: 3% 5% 5% 5%;
  text-align: center;
}
.web-sections-dark {
  padding: 3% 5% 5% 5%;
  text-align: center;
  background: #4167ef26;
}
.web-layout {
  background-color: #ffffff;
}
.wep-page-header {
  padding: 2% 4%;
  background: #7894f745; /*7894f745  e1b967*/
  margin-top: 6%;
  margin-left: 4%;
  margin-right: 4%;
  font-family: 'Jost', sans-serif !important;
  color: #444;
  border-radius: 55px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  font-size: 14px;
}
.web-page-container {
  padding: 2% 4%;
}
.web-box {
  padding: 3%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 25px;
}
.web-inner-box {
  padding: 5% 0% 2% 0%;
}
/******************************************************************************** GENERAL */
.txt-align-end {
  text-align: -webkit-right;
}
.dis-flex {
  display: flex;
}
.pd-rit-1{
  padding-right: 1%;
}
.header-h1 {
  font-family: 'Jost', sans-serif !important;
  padding: 2%;
  color: #4e4e4e;
  font-size: 2em;
}
.header-h2 {
  font-family: 'Jost', sans-serif !important;
  padding: 3% 1% 1% 1%;
  color: #1c358f;
  font-size: 1.5em;
}
.header-h3 {
  font-family: 'Jost', sans-serif !important;
  padding: 3% 1% 1% 1%;
  color: #0a1230;
}
/************************************************************************************** RESPONSIVE */
@media screen and (min-width: 320px) and (max-width: 400px) {
  .header-h1 {
    font-size: 1.7em;
  }
  .header-h2 {
    font-size: 1.2em;
  }
  .wep-page-header {
    padding: 3% 5%;
    margin-top: 33%;
    font-size: 10px;
  }
}
@media screen and (min-width: 401px) and (max-width: 576px) {
  .wep-page-header {
    padding: 3% 5%;
    margin-top: 24%;
    font-size: 10px;
  }
}
@media screen and (min-width: 577px) and (max-width: 768px) {
  .wep-page-header {
    padding: 3% 5%;
    margin-top: 18%;
    font-size: 10px;
  }
}
@media screen and (min-width: 767px) and (max-width: 992px) {
  .wep-page-header {
    padding: 3% 5%;
    margin-top: 13%;
    font-size: 10px;
  }
}
@media screen and (min-width: 993px) and (max-width: 1200px) {
  .wep-page-header {
    padding: 3% 5%;
    margin-top: 10%;
  }
}
@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .wep-page-header {
    padding: 3% 5%;
    margin-top: 8%;
  }
}