.termspg-box p {
    padding: 0% 1% 0.4% 1%;
    font-size: 10pt;
    line-height: 1.5;
    color: #727272;
    width: 75vw;
}
.termspg-box .header-h2 {
    padding: 1% 1% 1% 1%;
    padding: 1% 1% 1% 1%;
    font-size: 13pt;
}