.web-navbar {
    /*background-color: #ffffffc7 !important;*/
    background-color:#ffffff96 !important;
    border-bottom: 3px solid #7894f7;
    height: 64px !important;
    padding: 0.5rem calc((100vw - 1000px) / 2);
    color: rgba(0, 0, 0, 0.85) !important;
    line-height: 64px !important;
    display: flex;
    justify-content: flex-start;
    position: fixed;
    z-index: 1;
    width: 100%;
    font-family: 'Jost', sans-serif !important;
}
.web-navbar-logo-btn {
    height: 285%;
    margin-top: 5%;
    background: #7894f7;
    border: none;
    border-bottom-left-radius: 45%;
    border-bottom-right-radius: 45%;
}
.web-navbar-logo {
    height: 85%;
    padding-top: 25%;
}
.web-navbar-header-logo {
    display: flex;
    align-items: center;
    /*padding: 0 1rem;*/
    height: 100%;
    cursor: pointer;
}
.web-navbar-home-bars {
    display: none;
    color: #fff;
}
.web-navbar-header-menu {
    display: flex;
    align-items: center;
    margin-right: -24px;
    width: 100vw;
    white-space: nowrap;
}
.web-navbar-header-menu-link {
    color: rgba(11, 26, 88, 0.5);
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
    padding: 0 1rem;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Jost', sans-serif !important;
}
.web-navbar-header-menu-btn {
    display: flex;
    align-items: center;
    margin-right: 24px;
    justify-content: flex-end;
    width: 100vw;
}
.web-navbar-link-active {
    position: relative;
    color: #001529;
}
.web-navbar-link-active::before {
    content: "\a0\a0\a0\a0\a0\a0\a0\a0";
    display: block;
    margin: 0 auto;
    position: absolute;
    /* text-decoration: underline; */
    width: 50px;
    top: 1px;
    overflow: hidden;
    color: #001529;
}
.web-navbar-home-login-btn {
    border-radius: 4px;
    background: #fff !important;
    /* padding: 11px 22px; */
    color: var(--Webprimary) !important;
    outline: none;
    border: 1px solid #001529;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    display: block;
}
.web-navbar-home-login-btn:hover {
    background: var(--Webprimary) !important;
    color: #fff !important;
}
.web-navbar-home-signup-btn {
    border-radius: 4px;
    background: var(--Webprimary) !important;
    /* padding: 11px 22px; */
    color: #fff !important;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    display: block;
}
.web-navbar-responsive-navbar {
    display: none;
}
.web-navbar-responsive-menu-link {
    cursor: pointer;
}
/************************************************************************************** ANTD OVERWRITTING */
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: 10px 12px;
    font-weight: 500;
    font-family: 'Jost', sans-serif !important;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
    font-weight: 700;
}
/************************************************************************************** RESPONSIVE */
@media screen and (min-width: 320px) and (max-width: 400px) {
    .web-navbar-logo {
        height: 80%;
        padding-top: 15%;
    }
    .web-navbar-logo-btn {
        height: 240%;
        margin-top: 5%;
        padding: 12% 10% 0% 10%;
    }
    .web-navbar {
        padding-inline: 10px !important;
    }
    .web-navbar-home-bars {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 5%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #333;
    }
    .web-navbar-header-menu {
        display: none;
    }
    .web-navbar-header-menu-btn {
        display: none;
    }
    .web-navbar-responsive-navbar {
        display: block;
        position: fixed;
        top: 0px;
        right: 0;
        width: 200px;
        height: 100%;
        transition: all 0.2s ease-in-out;
        background: #7894f7;
        z-index: 999;
    }
    .web-navbar-responsive-menu {
        padding-top: 64px;
        padding-left: 20px;
    }
    .web-navbar-responsive-menu-link {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        height: 40px;
        display: flex;
        align-items: center;
    }
    .web-navbar-close-sidebar {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 8%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
}

@media screen and (min-width: 401px) and (max-width: 576px) {
    .web-navbar-logo-btn {
        height: 270%;
        padding: 2% 8%;
    }
    .web-navbar-logo {
        height: 80%;
        padding-top: 23%;
    }
    .web-navbar-home-bars {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 5%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #333;
    }
    .web-navbar-header-menu {
        display: none;
    }
    .web-navbar-header-menu-btn {
        display: none;
    }
    .web-navbar-responsive-navbar {
        display: block;
        position: fixed;
        top: 0px;
        right: 0;
        width: 200px;
        height: 100%;
        transition: all 0.2s ease-in-out;
        background: #7894f7;
        z-index: 999;
    }
    .web-navbar-responsive-menu {
        padding-top: 64px;
        padding-left: 20px;
    }
    .web-navbar-responsive-menu-link {
        color: #fff;
        font-size: 14px;
        font-weight: 600;
        height: 40px;
        display: flex;
        align-items: center;
    }
    .web-navbar-close-sidebar {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 8%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
}

/*
@media screen and (max-width: 768px) {
    .web-navbar-home-bars {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 5%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #333;
    }
    .web-navbar-header-menu {
        display: none;
    }
    .web-navbar-header-menu-btn {
        display: none;
    }
    .web-navbar-responsive-navbar {
        display: block;
        position: fixed;
        top: 0px;
        right: 0;
        width: 200px;
        height: 100%;
        transition: all 0.2s ease-in-out;
        background: #7894f7;
        z-index: 999;
    }
    .web-navbar-close-sidebar {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 8%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #fff;
    }
    .web-navbar-responsive-menu {
        padding-top: 64px;
        padding-left: 20px;
    }
    .web-navbar-responsive-menu-link {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
    }
} */