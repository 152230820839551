.serpg-sky-p {
    padding: 1% 0%;
}
.serpg-ba .before-after-slider__delimiter-icon{
    background-image: url(../../assets/images/logo-black.png);
    background-size: 100%;
}
.serpg-list {
    list-style: none;
    line-height: 1.8;
    font-family: 'Jost', sans-serif !important;
    font-size: 12pt;
    padding-left: 3%;
}
.serpg-box {
    padding: 1% 10%;
}