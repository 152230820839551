.web-form-input.ant-input,
.web-form-input.ant-picker,
.web-form-input.ant-input-number,
.web-form-input.ant-input-password {
    width: 100%;
    height: 32px;
    color: var(--formFontColor) !important;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600 !important;
    padding: 0px 1rem;
    margin-bottom: 0;
    background: #fff;
    border: 1px solid rgba(58, 68, 59, 0.3);
    border-radius: 5px;
}
.web-form-input.ant-input:hover {
    border-color: rgba(58, 68, 59, 0.3);
    border-right-width: 1px;
}
.web-form-input > .ant-picker-input > input {
    font-weight: 600;
    color: var(--formFontColor) !important;
}
.web-form-input .ant-input-number-input {
    font-weight: 600 !important;
}
.web-form-input.ant-select,
.web-form-input .ant-select {
    color: var(--formFontColor);
}
.web-form-input .ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    color: var(--formFontColor);
}
.web-form-input .ant-select-selection-item {
    font-weight: 600;
}
.web-form-input.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.web-form-input.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: rgba(58, 68, 59, 0.3);
}
.web-form-textarea {
    width: 100%;
    color: rgba(41, 55, 95, 0.8);
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 15px;
    font-weight: 600;
    padding: 0px 1rem;
    margin-bottom: 0;
    background: #fff;
    border: 1px solid rgba(58, 68, 59, 0.3);
    border-radius: 3px;
}
.web-form-textarea:hover,
.web-form-textarea:focus,
.web-form-textarea:active {
    border: 1px solid rgba(58, 68, 59, 0.3);
}
/***************************************************************************************LABEL*/
.web-form-label {
    color: rgb(94 94 94);
    font-size: 14px;
    font-weight: 600;
    padding: 0px !important;
}