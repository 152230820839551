@import-normalize;
/* bring in normalize.css styles */
/*@import "~antd/dist/antd.css";*/
@import "./App.css";
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Righteous&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    margin: 0;
    font-family: 'Jost', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
code {
    font-family: 'Jost', sans-serif !important;
}
p {
    font-size: 12pt;
    font-family: 'Jost', sans-serif !important;
    line-height: 1.8;
}
/************************************************************************************** RESPONSIVE */
@media screen and (min-width: 320px) and (max-width: 400px) {
    p {
        font-size: 11pt;
    }
}